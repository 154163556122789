<template>
  <div id="layout-page">
    <component :is="getThemeMode.layout" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "",
  metaInfo() {
    return {
      title: this.themeModel?.meta_title || "89.9 TheLight",
      titleTemplate: `%s | ${window.VUE_APP_APP_NAME}`,
      meta: [
        {
          property: 'og:title',
          content: this.themeModel?.meta_title || "89.9 TheLight"
        }, {
          property: 'og:description',
          content: this.themeModel?.meta_descripition || ''
        }, {
          property: 'og:image',
          content: this.themeModel?.meta_image || ''
        }
      ]
    }
  },
  data() {
    return {
      layoutName: "",
      themeModel: ''
    };
  },
  computed:{
    ...mapGetters(["themeApiModel", "getThemeMode"])
  },
  watch: {
    themeApiModel: function(newValue) {
      this.themeModel = newValue;
    }
  },
  created() {
    this.themeModel = this.themeApiModel;
    const slug = this.$router.currentRoute.query.slug;
    this.getThemeModel(slug);
    this.getThemeType();
  },
  methods: {
    ...mapActions(['getThemeModel', 'getThemeType'])
  }
};
</script>

<style scoped>
</style>